import React from "react";
import "./App.css";
import "./output.css";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import RouterPrincipal from "./components/router/RouterPrincipal";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga";

const TRACKING_ID = "G-D66RJBGHM6"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// Crear un cliente de Apollo
const client = new ApolloClient({
  uri: "https://manage.finniu.com/api/v1/graph/finniu/", // Reemplaza con la URL de tu servidor GraphQL
  cache: new InMemoryCache(),
});

function App() {
  // const [isDarkMode, setIsDarkMode] = useState(false);
  return (
    <ApolloProvider client={client}>
      <Router>
        <div className="App">
          <RouterPrincipal />
        </div>
      </Router>
    </ApolloProvider>
  );
}
export default App;
