import React, { useEffect } from "react";

const CardRentability = ({
  currentMonthState,
  currentYearState,
  importantDays,
  showPassRentabilityState,
  isDarkModeState,
}) => {
  useEffect(() => {}, [currentMonthState, currentYearState]);

  const data = showPassRentabilityState
    ? importantDays.pastPaymentDays
    : importantDays.remainingPaymentDays;

  return (
    <div className="flex flex-col justify-center">
      {data.map((payDay, index) => (
        <div
          key={index}
          className={`${
            showPassRentabilityState
              ? "bg-custom-chart-lilac border-custom-border-lilac"
              : "bg-custom-chart-rentability border-custom-blue-rentability"
          } border-4 rounded-xl p-4 mt-1 w-[422px]`}
        >
          <div className="text-container">
            <div className="flex justify-between date">
              <h1
                className={`font-bold ${
                  isDarkModeState ? "text-white" : "text-black"
                }`}
              >
                {payDay.date}
              </h1>
              {/* {showPassRentabilityState && (
                <h2 className="text-black">Ver voucher</h2>
              )} */}
            </div>
            <div className="flex justify-between amount">
              <h2
                className={`${isDarkModeState ? "text-white" : "text-black"}`}
              >
                {payDay.description}
              </h2>
              {/* {showPassRentabilityState && (
                <FontAwesomeIcon
                  className="mt-6 text-black cursor-pointer eye-icon"
                  icon={faEye}
                />
              )} */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardRentability;
