export const saveIsOpenSidebarStorage = (isOpen) => {
  localStorage.setItem("isOpen", isOpen);
};

export const getIsOpenSidebarStorage = () => {
  return localStorage.getItem("isOpen");
};
export const removeIsOpenSidebarStorage = () => {
  localStorage.removeItem("isOpen");
};
export const saveSelectedMenuStorage = (selectedMenu) => {
  localStorage.setItem("selectedMenu", selectedMenu);
};
export const removeSelectedMenuStorage = () => {
  localStorage.removeItem("selectedMenu");
};

export const getSelectedMenuStorage = () => {
  return localStorage.getItem("selectedMenu");
};
