import LogoTextLight from "../images/logoTextLight.png";
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { LOGIN_USER } from "../graphql/mutation";
import { saveToken } from "../helpers/TokenStorage";
import { saveRefreshToken } from "../helpers/TokenStorage";
import { isMobile } from "react-device-detect";
import {
  getCredentials,
  saveCredentials,
  saveRememberPasswordChecked,
} from "../helpers/CredentialsStorage";
import { useNavigate } from "react-router-dom";
import { isRememberPasswordChecked } from "../helpers/CredentialsStorage";
import { messageNotify } from "../components/MessageNotification";
import { Toaster } from "react-hot-toast";

const LoginComponent = () => {
  let navigate = useNavigate();
  let [emailState, setEmailState] = useState("");

  let [passwordState, setPasswordState] = useState("");
  let [showPasswordState, setShowPasswordState] = useState(false);
  let [rememberCredentialsState, setRememberCredentialsState] = useState(
    isRememberPasswordChecked()
  );

  useEffect(() => {
    if ((window.innerWidth <= 375 || window.innerWidth <= 640) && isMobile) {
      navigate("/download-app");
    } else {
      navigate("/login");
    }
  }, [navigate]);
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/home");
    } else {
      const credentials = getCredentials();

      if (credentials) {
        setEmailState(credentials.email || "");
        setPasswordState(credentials.password || "");
      }
    }
  }, [navigate]);

  let [loginUser] = useMutation(LOGIN_USER);

  let handleLogin = async () => {
    try {
      let { data: loginResponse } = await loginUser({
        variables: { email: emailState, password: passwordState },
      });

      if (loginResponse?.tokenAuth?.token) {
        saveToken(loginResponse.tokenAuth.token);
        saveRefreshToken(loginResponse.tokenAuth.refreshToken);

        saveRememberPasswordChecked(rememberCredentialsState);

        if (rememberCredentialsState === true) {
          saveCredentials(emailState, passwordState);
        } else {
          localStorage.removeItem("credentials");
        }

        navigate("/home");
      } else {
        messageNotify({ message: "Usuario y/o contraseña incorrectos" });
      }
    } catch (error) {
      console.error("failed to login:", error);
      messageNotify({ message: "Usuario y/o contraseña incorrectos" });
    }
  };

  let togglePasswordVisibility = () => {
    setShowPasswordState(!showPasswordState);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleLogin();
      }}
    >
      <div className="flex h-screen bg-gradient-to-t from-creme-color to-custom-light-blue">
        <div className="m-auto h-[721px] bg-[white] rounded-[50px] flex flex-col justify-center items-center w-[365px] sm:w-[500px] lg:w-[555px] xl:w-[610px]">
          <div className="flex flex-col items-center justify-center">
            <img
              className="w-[90px] h-[90px] mb-[15px] self-center"
              src={LogoTextLight}
              alt="logo"
            />
            <p className="text-lg md:text-xl lg:text-2xl font-normal pt-[35px] text-center">
              Hola, ingresa a tu cuenta
            </p>
            <input
              className="mx-[15px] self-center pl-[22px] w-[346px] md:w-[390px]lg:w-[428px] xl:w-[430px] h-[60px] rounded-[26px] border-[1.9px] border-solid border-[#3DD0FB] mt-[35px] text-[20px] shadow-lg"
              type="text"
              placeholder="Correo electrónico"
              value={emailState}
              onChange={(e) => setEmailState(e.target.value)}
              autoComplete="username"
            />
            <div className="flex flex-row items-center justify-center">
              <input
                className="mx-[15px] self-center pl-[22px] w-[346px] md:w-[390px]lg:w-[428px] xl:w-[430px] h-[60px] rounded-[26px] border-[1.9px] border-solid border-[#3DD0FB] mt-[35px] text-[20px] shadow-lg "
                type={showPasswordState ? "text" : "password"}
                placeholder="Contraseña"
                value={passwordState}
                onChange={(e) => setPasswordState(e.target.value)}
                autoComplete="current-password"
              />
              <div>
                <FontAwesomeIcon
                  className="mb-[-4px] w-[45px] h-[18px] relative top-4  mt-[10px] ml-[-72px] cursor-pointer "
                  icon={showPasswordState ? faEye : faEyeSlash}
                  onClick={togglePasswordVisibility}
                />
              </div>
            </div>

            <p
              onClick={() => navigate("/wrong-password")}
              className="text-[14px] md:text-[16px] lg:text-[18px] font-normal flex justify-end ml-30 pb-[10px] self-end my-5  mr-[20px] cursor-pointer "
            >
              ¿Olvidaste tu contraseña?
            </p>

            <div className="flex ml-[28px] mb-[23px] gap-[5px] ">
              <input
                className="w-5 h-5 bg-white border-2 border-gray-300 rounded-md checked:bg-black checked:border-transparent checked:border-2 checked:border-black"
                type="checkbox"
                id="myCheckbox"
                checked={rememberCredentialsState}
                onChange={() =>
                  setRememberCredentialsState(!rememberCredentialsState)
                }
              />

              <label htmlFor="myCheckbox"></label>
              <p className="sm:max-w-80 md:max-w-[322px] lg:max-w-[324px] xl:max-w-[379px] ">
                Guardar mis credenciales para los futuros ingresos
              </p>
            </div>

            <button
              className="w-[184px] h-[48px] text-[white] rounded-[20px] bg-[rgba(13,_58,_92,_1)] font-bold text-[18px] self-center"
              style={{ cursor: "pointer" }}
              onClick={handleLogin}
            >
              Entrar
            </button>
            <Toaster />
            <p
              className="text-[15px] sm:text-[17px] md:text-[19px] lg[22px] font-normal pt-[20px] text-center cursor-pointer "
              onClick={() => navigate("/download-app")}
            >
              ¿Aún no tienes una cuenta?
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginComponent;
