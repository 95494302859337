import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "../../pages/LoginPage";
import HomePage from "../../pages/HomePage";
import CalendarPage from "../../pages/CalendarPage";
import MyHistory from "../../pages/MyHistory";
import DownloadApp from "../../pages/DownloadApp";
import Tour from "../tour/Tour";
import SwitchSolesTour from "../SwitchSolesTour";
import WrongPassword from "../../pages/WrongPassword";
import AccountVerificationScreen from "../../pages/AccountVerificationScreen";
import ChangePassword from "../../pages/ChangePassword";
import ModalComponent from "../ModalComponent";

const RouterPrincipal = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/calendar" element={<CalendarPage />} />
      <Route path="my-history" element={<MyHistory />} />
      <Route path="download-app" element={<DownloadApp />} />
      <Route path="tour" element={<Tour />} />
      <Route path="switch-tour" element={<SwitchSolesTour />} />
      <Route path="wrong-password" element={<WrongPassword />} />
      <Route
        path="account-verification"
        element={<AccountVerificationScreen />}
      />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="modal" element={<ModalComponent />} />
    </Routes>
  );
};

export default RouterPrincipal;
