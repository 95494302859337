import React from "react";
import PlanInProgressCard from "./PlanInProgressCard";

const PlansInProgress = ({ isSolesState, isDarkModeState, dataReport }) => {
  const isSoles = isSolesState;

  const reportSoles = dataReport.userInfoAllInvestment["invesmentInSoles"][0];

  const reportDollar =
    dataReport.userInfoAllInvestment["invesmentInDolares"][0];

  dataReport = isSoles ? reportSoles : reportDollar;
  const dataReportInCourse = dataReport.invesmentInCourse;

  return (
    <div>
      <div
        className={`cursor-pointer box-border p-[30px] max-w-[598px] min-w-[300px]  h-[347px] w-[550px] rounded-[20px] shadow-md hover:shadow-lg ${
          isDarkModeState
            ? "bg-gradient-to-b from-custom-blue to-custom-light-blue"
            : "bg-gradient-to-t from-creme-color to-custom-light-blue"
        }`}
      >
        <div className="h-full overflow-auto">
          <div
            className={`font-bold flex-col justify-start items-start ${
              isDarkModeState ? "text-custom-lightblue" : "text-custom-blue"
            }`}
          >
            <h1 className="flex text-xl"> Mis planes en curso </h1>
            {dataReportInCourse.length > 0 ? (
              <div>
                {dataReportInCourse.map((plan, index) => (
                  <PlanInProgressCard
                    key={index}
                    isDarkModeState={isDarkModeState}
                    {...plan}
                    isSoles={isSoles}
                  />
                ))}
              </div>
            ) : (
              <div>
                <p
                  className={`text-xl m-24 ${
                    isDarkModeState ? "text-white" : "text-black"
                  }`}
                >
                  {isSoles
                    ? "Usted no cuenta con planes en soles."
                    : "Usted no cuenta con planes en dólares."}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlansInProgress;
