import React from "react";

const ButtonsComponent = ({
  selectButtonsState,
  setSelectButtonState,
  isDarkModeState,
}) => {
  const handleButtonClick = (buttonState) => {
    setSelectButtonState(buttonState);
  };

  function getButtonStyles(buttonState, isDarkModeState) {
    const isSelected = selectButtonsState === buttonState;
    const background = isSelected
      ? isDarkModeState
        ? "#A2E6FA"
        : "#18507B"
      : isDarkModeState
      ? "#0D3A5C"
      : "#dbf7ff";
    const color = isSelected
      ? isDarkModeState
        ? "#000000"
        : "#FFFFFF"
      : isDarkModeState
      ? "#FFFFFF"
      : "#000000";
    const border = isSelected ? background : "#dbf7ff";

    return { background, color, border };
  }

  return (
    <div className="flex flex-row gap-5 p-3">
      <div className="mt-4">
        <button
          className="cursor-pointer gap-1.5 rounded-3xl w-28 h-10 mr-2 container-hover text-md font-semibold shadow-lg"
          onClick={() => handleButtonClick("inCourse")}
          style={getButtonStyles("inCourse", isDarkModeState)}
        >
          En curso
        </button>
        <button
          className="cursor-pointer gap-1.5 rounded-3xl w-28 h-10 mr-2 container-hover text-md font-semibold shadow-lg"
          onClick={() => handleButtonClick("toConfirm")}
          style={getButtonStyles("toConfirm", isDarkModeState)}
        >
          En proceso
        </button>
        <button
          className="cursor-pointer gap-1.5 rounded-3xl w-28 h-10 mr-2 container-hover text-md font-semibold shadow-lg"
          onClick={() => handleButtonClick("finish")}
          style={getButtonStyles("finish", isDarkModeState)}
        >
          Finalizadas
        </button>

        <button
          className="cursor-pointer gap-1.5 rounded-3xl w-28 h-10 mr-2 container-hover text-md font-semibold shadow-lg"
          onClick={() => handleButtonClick("rejected")}
          style={getButtonStyles("rejected", isDarkModeState)}
        >
          Rechazadas
        </button>
      </div>
    </div>
  );
};

export default ButtonsComponent;
