export const saveIsDarkModeStorage = (isDark) => {
  localStorage.setItem("isDark", isDark);
};

export const getIsDarkModeStorage = () => {
  return localStorage.getItem("isDark");
};

export const removeIsDarkModeStorage = () => {
  localStorage.removeItem("isDark");
};
