export const saveUserInfoAllInvestment = (userInfoInvestment) => {
  localStorage.setItem("userInfoInvestment", userInfoInvestment);
};

export const getInfoAllInvestment = () => {
  return localStorage.getItem("userInfoInvestment");
};

export const removeInfoAllInvestment = () => {
  localStorage.removeItem("userInfoInvestment");
};
