import React from "react";
import { useQuery } from "@apollo/client";
import client from "../client";
import { IMPORTANT_DAYS } from "../graphql/querys";

const ImportantDayListComponent = ({ isDarkModeState }) => {
  const { loading, error, data } = useQuery(IMPORTANT_DAYS, { client });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      {data.importantDays.map((day, index) => (
        <div
          key={index}
          className="w-[422px] flex flex-col p-3 ml-3 border-custom-blue-rentability border-4 rounded-xl bg-custom-lightblue"
        >
          <div className="text-container">
            <div className="flex justify-between date">
              <h1
                className={`font-bold ${
                  isDarkModeState ? "text-white" : "text-black"
                }`}
              >
                {day.date}
              </h1>
            </div>
            <div className="flex justify-between amount">
              <h2
                className={`${isDarkModeState ? "text-white" : "text-black"}`}
              >
                {day.description}
              </h2>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImportantDayListComponent;
