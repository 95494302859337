import React from "react";
import CountUp from "react-countup";
import { saveUserInfoAllInvestment } from "../helpers/UserInfoAllInvestmentStorage";

const InprogressInvestmentReport = ({ isSolesState, dataReport }) => {
  const isSoles = isSolesState;

  {
    const reportSoles = dataReport.userInfoAllInvestment["invesmentInSoles"][0];

    const reportDollar =
      dataReport.userInfoAllInvestment["invesmentInDolares"][0];

    dataReport = isSoles ? reportSoles : reportDollar;
    saveUserInfoAllInvestment(
      "userInfoInvestment",
      JSON.stringify(dataReport.userInfoAllInvestment)
    );
  }
  const getMoneySymbol = (isSoles) => (isSoles ? "S/ " : "$ ");

  let totalBalanceAmmount = parseFloat(dataReport.totalBalanceAmmount) || 0;
  let totalBalanceRentability =
    parseFloat(dataReport.totalBalanceRentability) || 0;
  let totalBalanceRentabilityIncreased =
    parseFloat(dataReport.totalBalanceRentabilityIncreased) || 0;

  // let balanceDifference = totalBalanceAmmount - totalBalanceRentability;
  let capitalInCourse = dataReport.capitalInCourse || 0;

  return (
    <div
      className={` cursor-pointer ${
        isSoles ? "bg-custom-light-blue" : "bg-creme-color"
      } p-10 w-[550px] h-[319px] rounded-[20px] shadow-md hover:shadow-lg`}
    >
      <div className="flex justify-start text-xl font-bold text-custom-blue">
        <h1>Mi inversión en curso</h1>
      </div>
      <div className="grid-container">
        <div className="flex flex-col gap-4">
          <div className="text-lg text-start">
            <h2>Inversión Histórica</h2>
          </div>
          <div>
            {
              <h1 className="text-2xl font-bold">
                {getMoneySymbol(isSoles)}
                <CountUp
                  start={0}
                  end={totalBalanceAmmount}
                  duration={2.75}
                  separator=","
                  decimals={2}
                />
              </h1>
            }
          </div>
          <div className="text-lg text-start">
            <h2>Intereses Históricos</h2>
          </div>
          <div>
            {
              <h4 className="text-2xl ">
                {getMoneySymbol(isSoles)}
                <CountUp
                  start={0}
                  end={totalBalanceRentability}
                  duration={2.75}
                  separator=","
                  decimals={2}
                />
              </h4>
            }
          </div>
        </div>

        <div
          className={`${
            isSoles ? "border-custom-lightblue" : "border-custom-blue"
          } h-44 w-0  flex  items-center justify-center  rounded-xl border-4 `}
        ></div>

        <div className="flex flex-col justify-center gap-4 ">
          <div className="text-lg text-start">
            <h1> Inversión en Curso</h1>
          </div>
          <div>
            {
              <h4 className="text-2xl text-custom-green-secondary">
                {getMoneySymbol(isSoles)}
                <CountUp
                  start={0}
                  end={capitalInCourse}
                  duration={2.75}
                  separator=","
                  decimals={2}
                />
              </h4>
            }
          </div>

          <div className="text-lg text-start ">
            <h2>Total intereses generados</h2>
          </div>
          <div>
            {
              <h4 className="text-2xl text-custom-green-secondary">
                {getMoneySymbol(isSoles)}
                <CountUp
                  start={0}
                  end={totalBalanceRentabilityIncreased}
                  duration={2.75}
                  separator=","
                  decimals={2}
                />
              </h4>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default InprogressInvestmentReport;
