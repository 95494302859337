import toast from "react-hot-toast";
import sadIcon from "../images/sadIcon.png";
import slickRed from "../images/slickRed.png";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import slickGreen from "../images/slickGreen.png";
import happyIcon from "../images/happyIcon.png";

const MessageNotification = ({
  type = "error",
  message = "hubo un error ",
}) => {
  const handleDismiss = () => {
    toast.dismiss();
  };

  return (
    <div
      className={`relative flex flex-row rounded-tl-[50px] rounded-br-[27px] rounded-tr-[15px] rounded-bl-[20px] border-[2px] ${
        type === "error" ? "border-custom-red" : "border-custom-green"
      } w-[306px] h-[90px] bg-[white]`}
    >
      <div className="relative">
        <img
          src={type === "error" ? slickRed : slickGreen}
          alt="SlickRed"
          className="w-[110px] h-[90px] relative -left-[3px] -top-[3px]"
        />

        <img
          className="w-[50px] h-[50px] absolute left-[38px] bottom-[10px]"
          src={type === "error" ? sadIcon : happyIcon}
          alt="Icono"
          width="50"
          height="50"
        />
      </div>
      <div className=" flex flex-col relative mt-[2px] items-baseline  ">
        <FontAwesomeIcon
          className="absolute text-[black] h-[20px] w-[20px] left-[170px] top-[5px] cursor-pointer"
          icon={faTimes}
          onClick={handleDismiss}
        />
        <h1
          className={`text-[13px] ${
            type === "error" ? "text-custom-red" : "text-custom-green"
          } font-bold leading-[2.0]`}
        >
          {type === "error" ? "Upss, algo salió mal" : "Completado con éxito"}
        </h1>
        <h2 className="text-[13px] text-[#000000] font-bold"> {message}</h2>
      </div>
    </div>
  );
};

export const messageNotify = ({
  message = "hubo un error ",
  type = "error",
  position = "bottom-center",
  duration = 3000,
}) => {
  toast.custom(<MessageNotification type={type} message={message} />, {
    position: position,
    duration: duration,
  });
};

export default MessageNotification;
