import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TourInvestment from "./TourInvestment";
import { useNavigate } from "react-router-dom";
import TourHistory from "./TourHistory";
import TourStepCero from "./TourStepCero";
import TourCalendar from "./TourCalendar";
import TourSolesDolares from "./TourSolesDolares";
import TourNotification from "./TourNotification";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getShowTourStorage,
  saveShowTourStorage,
} from "../../helpers/ShowTourStorage";

const TourComponentStyled = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1000;

  .body-tour {
    width: 100%;
    height: 100%;
    background-color: rgba(13, 58, 92, 90%);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .body-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    line-height: 1.8;
    width: 100%;
    // height: 100%;
  }

  .body-text {
    // width: 657px;
    height: 151px;
    font-size: 25px;
    margin-left: 140px;
  }

  .row-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 180px;
    // padding-top: 50px;
    justify-content: start;
    margin-left: 290px;
    // padding-bottom: 380px;
  }

  span {
    color: #a2e6fa;
  }

  .button1 {
    width: 153px;
    height: 60px;
    background-color: #0d3a5c;
    border-radius: 26px;
    border: none;
    font-size: 24px;
    font-weight: normal;
    color: #ffffff;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .body-text h1 {
    font-weight: normal;
    color: #ffffff;
  }

  .body-text h2 {
    font-weight: normal;
    font-size: 30px;
    color: #ffffff;
  }
  // img {
  //   width: 129px;
  //   height: 109px;
  //   margin-left: 30px;
  // }

  .body-tour svg {
    height: 49px;
    // position: fixed;
    top: 40px;
    right: 45px;
    margin: 15px;
  }

  .closed {
    position: fixed;
  }
`;

//Review this part please
const Tour = ({ setTourVisibleState }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    const showTour = getShowTourStorage();
    if (showTour === "true") {
      setTourVisibleState(false);
    }
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [setTourVisibleState]);

  const handleContinue = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleCloseTour = () => {
    setTourVisibleState(false);
    saveShowTourStorage(false);
    // estado para ocultar el tour
  };

  function getTourStep(pageNumber) {
    switch (pageNumber) {
      case 1:
        return (
          <TourStepCero
            handleContinue={handleContinue}
            handleCloseTour={handleCloseTour}
            setCurrentPage={setCurrentPage}
          />
        );
      case 2:
        return <TourInvestment handleContinue={handleContinue} />;
      case 3:
        return <TourHistory handleContinue={handleContinue} />;
      case 4:
        return <TourCalendar handleContinue={handleContinue} />;
      case 5:
        return (
          <TourSolesDolares
            handleContinue={handleContinue}
            // handleSwitchChange={handleSwitchChange}
            // isSolesState={isSolesState}
            // setIsSolesState={setIsSolesState}
          />
        );
      case 6:
        return (
          <TourNotification
            handleContinue={handleContinue}
            handleCloseTour={handleCloseTour}
          />
        );

      default:
        return null;
    }
  }

  return (
    <TourComponentStyled>
      <div className="body-tour">
        <FontAwesomeIcon
          onClick={() => {
            navigate("/home?forceTour=false");
            handleCloseTour();
            saveShowTourStorage(false);
          }}
          style={{ height: "42px", color: "#A2E6FA" }}
          icon={faTimes}
          position="fixed"
          height={"42px"}
          className="closed"
          top={"38px"}
        />
        <div className="body-container">{getTourStep(currentPage)}</div>
      </div>
    </TourComponentStyled>
  );
};

export default Tour;
