export const saveShowTourStorage = (showTour) => {
  localStorage.setItem("showTour", showTour);
};

export const getShowTourStorage = () => {
  return localStorage.getItem("showTour");
};
export const removeShowTourStorage = () => {
  localStorage.removeItem("showTour");
};
