export const setProfile = (profile) => {
  localStorage.setItem("userProfile", JSON.stringify(profile));
};

export const getProfile = () => {
  const userProfileString = localStorage.getItem("userProfile");
  return userProfileString ? JSON.parse(userProfileString) : null;
};

export const removeProfile = () => {
  localStorage.removeItem("userProfile");
};
