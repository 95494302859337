import UserMenu from "../components/UserMenu";
import Tour from "../components/tour/Tour";
import InprogressInvestmentReport from "../components/InprogressInvestmentReport";
import SideBarComponent from "../components/SideBarComponent";
import SwitchSolesComponent from "../components/SwitchSolesComponent";
import { USER_PROFILE } from "../graphql/querys";
import React, { useState, useEffect } from "react";
import RevenuePaymentReport from "../components/RevenuePaymentsReport";
import PlansInProgress from "../components/PlansInProgress";
import CurveChartComponent from "../components/CurveChartComponent";
import { getShowTourStorage } from "../helpers/ShowTourStorage";
import { getIsDarkModeStorage } from "../helpers/IsDarkModeStorage";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getIsSoles, saveIsSoles } from "../helpers/IsSolesStorage";
import { useQuery } from "@apollo/client";
import client from "../client";
import { USER_INFO_ALL_INVESTMENT } from "../graphql/querys";

const HomePage = () => {
  const [isSolesState, setIsSolesState] = useState(
    getIsSoles() === "true" ? true : false
  );

  const [isDarkModeState, setIsDarkModeState] = useState(false);
  const [tourVisibleState, setTourVisibleState] = useState(true);

  const [userName, setUserName] = useState("Usuario");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const forceTour = searchParams.get("forceTourVisible");

  const navigate = useNavigate();

  const { loading, error, data } = useQuery(USER_INFO_ALL_INVESTMENT, {
    client,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("token home", token);
    if (!token || token === "null" || token === "") {
      navigate("/login");
    } else {
      let isDarkModeStorage = getIsDarkModeStorage();

      if (isDarkModeStorage === "true") {
        setIsDarkModeState(true);
      } else {
        setIsDarkModeState(false);
      }

      if (forceTour === true || forceTour === "true") {
        setTourVisibleState(true);
      } else {
        let showTour = getShowTourStorage();

        if (showTour === "false" || showTour === false) {
          setTourVisibleState(false);
        } else {
          setTourVisibleState(true);

          //change current select menu to home
          // localStorage.setItem("currentSelectMenu", "home");
        }
      }

      const fetchData = async () => {
        try {
          const { data } = await client.query({
            query: USER_PROFILE,
            fetchPolicy: "network-only",
          });

          if (data && data.userProfile) {
            localStorage.setItem(
              "userProfile",
              JSON.stringify(data.userProfile)
            );
            setUserName(data.userProfile.firstName);
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      };

      fetchData();
    }
  }, [navigate, forceTour, tourVisibleState]);
  var dataReport = data;

  if (loading) return "";
  if (error) return ` ${error}`;
  if (!data.userInfoAllInvestment) return "";
  if (data.userInfoAllInvestment) {
  }

  const handleSwitchChange = () => {
    setIsSolesState(!isSolesState);
    saveIsSoles(!isSolesState);
  };

  return (
    <div className="h-full min-h-screen">
      <div
        className={`h-full min-h-screen ${
          isDarkModeState ? "bg-background-dark" : "bg-custom-background-light"
        }`}
      >
        <div className="flex flex-row w-screen h-full min-h-screen gap-15">
          {tourVisibleState && (
            <Tour
              setTourVisibleState={setTourVisibleState}
              handleSwitchChange={handleSwitchChange}
              isSolesState={isSolesState}
              setIsSolesState={setIsSolesState}
            />
          )}
          <SideBarComponent
            isDarkModeState={isDarkModeState}
            currentPage={tourVisibleState ? "tour" : "home"}
            className="h-full"
          />
          <div className="flex flex-col p-10 mx-auto max-w-1800px my-15">
            <UserMenu
              isDarkModeState={isDarkModeState}
              setIsDarkModeState={setIsDarkModeState}
            />
            <div className="flex items-center justify-between mb-10">
              <p
                className={`text-3xl font-bold flex flex-row justify-start   ${
                  isDarkModeState
                    ? "text-custom-light-blue"
                    : "text-custom-blue"
                }`}
              >
                Hola {userName}
              </p>
              <SwitchSolesComponent
                isSolesState={isSolesState}
                onSwitchChange={handleSwitchChange}
                isDarkModeState={isDarkModeState}
                setIsDarkModeState={setIsDarkModeState}
              />
            </div>
            <div className="flex justify-center flex-col gap-[30px] md:flex-col lg:flex-col xl:flex-row items-center lg:text-center mt-6">
              <div className="flex flex-col">
                <InprogressInvestmentReport
                  isSolesState={isSolesState}
                  dataReport={dataReport}
                />
              </div>
              <div className="flex flex-col">
                <RevenuePaymentReport
                  isDarkModeState={isDarkModeState}
                  isSolesState={isSolesState}
                />
              </div>
            </div>
            <div className="flex justify-center flex-col gap-[30px] md:flex-col lg:flex-col xl:flex-row  items-center lg:text-center mt-6">
              <div className="flex flex-col">
                <CurveChartComponent
                  isDarkModeState={isDarkModeState}
                  isSolesState={isSolesState}
                />
              </div>
              <div className="flex flex-col mt-6">
                <PlansInProgress
                  isDarkModeState={isDarkModeState}
                  isSolesState={isSolesState}
                  dataReport={dataReport}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
