import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getRefreshToken, getToken } from "./helpers/TokenStorage";
import { isTokenExpired } from "./helpers/JwtUtils";
import { refreshToken } from "./helpers/RefreshToken";

const httpLink = createHttpLink({
  uri: "https://manage.finniu.com/api/v1/graph/finniu/",
});

const authLink = setContext((_, { headers }) => {
  let token = getToken();

  if (isTokenExpired(token)) {
    const tokenRefresh = getRefreshToken();

    return refreshToken(tokenRefresh).then((newToken) => {
      return {
        headers: {
          ...headers,
          authorization: newToken ? `JWT ${newToken}` : "",
        },
      };
    });
  } else {
    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : "",
      },
    };
  }
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
