import React, { useState, useEffect } from "react";
import { USER_INFO_ALL_INVESTMENT } from "../graphql/querys";
import { useQuery } from "@apollo/client";
import client from "../client";
import SideBarComponent from "../components/SideBarComponent";
import UserMenu from "../components/UserMenu";
import Transactions from "../components/Transactions";
import HistoryChartComponent from "../components/HistoryChartComponent";
import ButtonsComponent from "../components/ButtonsComponent";
import CircleHistory from "../components/CircleHistory";
import { useNavigate } from "react-router-dom";
import SwitchSolesComponent from "../components/SwitchSolesComponent";
import { getIsSoles, saveIsSoles } from "../helpers/IsSolesStorage";
const MyHistory = () => {
  const [isSolesState, setIsSolesState] = useState(
    getIsSoles() === "true" ? true : false
  );
  const [isDarkModeState, setIsDarkModeState] = useState(false);
  // const [showSolesState, setShowSolesState] = useState(true);
  const [selectButtonsState, setSelectButtonState] = useState("inCourse");
  let navigate = useNavigate();

  const { loading, error, data } = useQuery(USER_INFO_ALL_INVESTMENT, {
    client,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }

    const getDarkModeFromLocalStorage = () => {
      const darkModeFromStorage = localStorage.getItem("isDark");
      if (darkModeFromStorage !== null) {
        setIsDarkModeState(darkModeFromStorage === "true");
      }
    };

    getDarkModeFromLocalStorage();
  }, [navigate]);

  if (loading) return "";
  if (error) return ` ${error}`;
  if (!data.userInfoAllInvestment) return "";

  const handleSwitchChange = () => {
    setIsSolesState(!isSolesState);
    saveIsSoles(!isSolesState);
  };
  var dataReport = data;

  return (
    <div
      className={`w-full h-full min-h-screen ${
        isDarkModeState ? "bg-background-dark" : "bg-custom-background-light"
      }`}
    >
      <div className="flex flex-row h-full min-h-screen gap-4">
        <SideBarComponent isDarkModeState={isDarkModeState} />
        <div className="flex flex-col p-10 mx-auto max-w-1800px my-15">
          <UserMenu
            label="mode dark"
            isDarkModeState={isDarkModeState}
            setIsDarkModeState={setIsDarkModeState}
          />

          <div className="flex items-center justify-between pl-2 ">
            <div className="text-2xl font-bold">
              <p style={{ color: isDarkModeState ? "#A2E6FA" : "#0D3A5C" }}>
                Mi historial
              </p>
            </div>
            <div>
              <SwitchSolesComponent
                isSolesState={isSolesState}
                onSwitchChange={handleSwitchChange}
                isDarkModeState={isDarkModeState}
                setIsDarkModeState={setIsDarkModeState}
              />
            </div>
          </div>
          <div className="flex flex-col pt-5 pl-3 ">
            <div className="flex justify-center gap-5 lg:items-center lg:flex-col md:flex-col xl:flex-row md:items-start">
              <div>
                <CircleHistory
                  isDarkModeState={isDarkModeState}
                  isSolesState={isSolesState}
                  dataReport={dataReport}
                />
              </div>
              <div
                className="flex flex-col gap-[31px] justify-center "
                // style={{ maxWidth: "500px" }}
              >
                {" "}
                {/* Ajusta el valor de maxWidth según tus necesidades */}
                {/* <div className="flex items-start content-start gap-4 md:ml-0 md:p-3 lg:p-0"> */}
                {/* <div className="text-center">
                    <button
                      className="button-money"
                      onClick={() => {
                        setShowSolesState(true);
                      }}
                    >
                      <h1
                        className="text-lg font-bold "
                        style={{
                          color: isDarkModeState ? "#EEEEEE" : "#0D3A5C",
                        }}
                      >
                        Soles
                      </h1>
                    </button>
                    <div
                      className="rounded-xl w-[120px] border-4 border-solid border-custom-lightblue "
                      style={{
                        display: showSolesState ? "block" : "none",
                      }}
                    ></div>
                  </div> */}
                {/* <div>
                    <button
                      className={` px-4 mx-4 border-none cursor-pointer outline-none text-lg ${
                        isDarkModeState ? "text-white" : "text-custom-blue"
                      }`}
                      onClick={() => {
                        setShowSolesState(false);
                      }}
                    >
                      <h1
                        className="text-lg font-bold"
                        style={{
                          color: isDarkModeState ? "#EEEEEE" : "#0D3A5C",
                        }}
                      >
                        Dólares
                      </h1>
                    </button>
                    <div
                      className="rounded-xl w-[120px] border-4 border-solid border-custom-lightblue"
                      style={{
                        display: showSolesState ? "none" : "block",
                      }}
                    ></div>
                  </div> */}
                {/* </div> */}
                <HistoryChartComponent
                  isDarkModeState={isDarkModeState}
                  isSolesState={isSolesState}
                  dataReport={dataReport}
                  // showSolesState={showSolesState}
                />
              </div>
            </div>
            <div className="flex justify-start pl-2 text-2xl font-bold md:mt-6 lg:mt-0 ">
              <h1 style={{ color: isDarkModeState ? "#A2E6FA" : "#0D3A5C" }}>
                Mis operaciones
              </h1>
            </div>

            <div className="flex flex-col items-start justify-center lg:m-auto  md:m-0  max-w-[1280px] ">
              <div className="flex items-start">
                <ButtonsComponent
                  isDarkModeState={isDarkModeState}
                  selectButtonsState={selectButtonsState}
                  setSelectButtonState={setSelectButtonState}
                />
              </div>

              <div>
                <Transactions
                  isDarkModeState={isDarkModeState}
                  isSolesState={isSolesState}
                  filterSelectedState={selectButtonsState}
                  dataReport={dataReport}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyHistory;
