import React from "react";
const SwitchSolesComponent = ({
  isSolesState,
  onSwitchChange,
  isDarkModeState,
}) => {
  const toggleSwitch = () => {
    onSwitchChange();
  };

  const switchStyles = {
    appearance: "none",
    width: "80px",
    height: "35px",
    borderRadius: "15px",
    backgroundColor: isSolesState ? "#A2E6FA" : "#0d3a5c",
    position: "relative",
    cursor: "pointer",
  };

  const beforeStyles = {
    content: "",
    position: "absolute",
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    backgroundColor: isSolesState ? "#0d3a5c" : "#A2E6FA",
    top: "5px",
    left: isSolesState ? "50px" : "10px",
    transition: "left 0.1s",
  };

  return (
    <div className="flex flex-row items-center justify-center">
      <div
        style={{
          marginRight: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "29px",
          color: isDarkModeState ? "#EEEEEE" : "#0D3A5C",
          padding: "15px",
        }}
      >
        {isSolesState ? "Soles" : "Dólares"}
      </div>

      <div style={switchStyles} onClick={toggleSwitch}>
        <div style={beforeStyles}></div>
      </div>
    </div>
  );
};

export default SwitchSolesComponent;
