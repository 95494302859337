export const saveCredentials = (email, password) => {
  localStorage.setItem("userEmail", email);
  localStorage.setItem("userPassword", password);
};

export const getCredentials = () => {
  const email = localStorage.getItem("userEmail");
  const password = localStorage.getItem("userPassword");
  return { email, password };
};

export const removeCredentials = () => {
  localStorage.removeItem("userEmail");
  localStorage.removeItem("userPassword");
};

export const saveRememberPasswordChecked = (isChecked) => {
  localStorage.setItem("rememberPassword", isChecked);
};

export let isRememberPasswordChecked = () => {
  return localStorage.getItem("rememberPassword") === "true";
};
