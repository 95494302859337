import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Finniu from "../images/logoSmallLight.png";
import ModalComponent from "./ModalComponent";
import CountUp from "react-countup";
import logoSmallDark from "../images/logoSmallDark.png";

const Transactions = ({
  isDarkModeState,
  isSolesState,
  filterSelectedState,
  dataReport,
}) => {
  const defaultBankLogoUrl = isDarkModeState ? logoSmallDark : Finniu;
  const isSoles = isSolesState;
  const [modalOpenState, setModalOpenState] = useState(false);
  const [voucherImages, setVoucherImages] = useState([]);
  const dataSoles = dataReport.userInfoAllInvestment.invesmentInSoles ?? [];
  const dataDolar = dataReport.userInfoAllInvestment.invesmentInDolares ?? [];

  let dataToRender = null;
  if (filterSelectedState === "inCourse") {
    dataToRender = isSoles
      ? dataSoles[0]["invesmentInCourse"]
      : dataDolar[0]["invesmentInCourse"];
  } else if (filterSelectedState === "toConfirm") {
    dataToRender = isSoles
      ? dataSoles[0]["invesmentInProcess"]
      : dataDolar[0]["invesmentInProcess"];
  } else if (filterSelectedState === "finish") {
    dataToRender = isSoles
      ? dataSoles[0]["invesmentFinished"]
      : dataDolar[0]["invesmentFinished"];
  } else if (filterSelectedState === "rejected") {
    dataToRender = isSoles
      ? dataSoles[0]["invesmentCanceled"]
      : dataDolar[0]["invesmentCanceled"];
  }

  const hasData = dataToRender && dataToRender.length > 0;

  const toggleModal = (voucherImages) => {
    setModalOpenState(!modalOpenState);
    setVoucherImages(voucherImages);
  };

  return (
    <div>
      <div
        className={`flex justify-center items-center xxl:w-[1260px] xl:w-[1000px] md:w-[563px] lg:w-[604px]  max-h-[250px] rounded-2xl  lg:m-5 md:m-0  lg:p-0 md:p-2 ${
          isDarkModeState ? "bg-custom-black" : "bg-white"
        } shadow-md`}
      >
        <div className="flex flex-row content-center gap-2.5 leading-none text-left h-[220px] mt-3 lg: ml-[-16px] md:ml-0  xxl:overflow-y-auto xxl:overflow-x-hidden xl:overflow-y-auto xl:overflow-x-auto lg:overflow-x-auto lg:overflow-y-auto md:overflow-auto">
          <div className="containers-overflow">
            <div
              className={`container-operation ${
                isDarkModeState ? "dark-mode-table" : "light-mode-table"
              }`}
            >
              {hasData ? (
                <table className="w-[1251px]">
                  <tbody>
                    <tr>
                      <th>Banco</th>
                      <th>Código de transacción</th>
                      <th>Monto</th>
                      <th>Plazo</th>
                      <th>Rentabilidad</th>
                      <th>Intereses mensuales</th>
                      {filterSelectedState !== "rejected" && <th>Voucher</th>}
                      <th>Inicio</th>
                    </tr>
                    {dataToRender.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <img
                            className="m-auto img-banks"
                            // src={isDarkModeState ? logoSmallDark : Finniu}
                            src={
                              item.depositBank
                                ? item.depositBank.bankLogoUrl ||
                                  defaultBankLogoUrl
                                : defaultBankLogoUrl
                            }
                            alt="finniu"
                          />
                        </td>
                        <td>{item.operationCode}</td>
                        <td>
                          {isSoles ? "S/" : "$"}{" "}
                          <CountUp
                            start={0}
                            end={item.amount}
                            duration={2.75}
                            separator=","
                            decimals={2}
                          />
                        </td>
                        <td> {item.deadline?.name}</td>
                        <td>
                          <CountUp
                            start={0}
                            end={item.rentabilityPercent}
                            duration={2.75}
                            separator=","
                            decimals={2}
                          />
                          {"%"}
                        </td>
                        <td>
                          {isSoles ? "S/" : "$"}
                          {item.rentabilityPerMonth !== null ? (
                            <CountUp
                              start={0}
                              end={item.rentabilityPerMonth}
                              duration={2.75}
                              separator=","
                              decimals={2}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        {filterSelectedState !== "rejected" && (
                          <td
                            style={{
                              color: isDarkModeState ? "#A2E6FA" : "#262525",
                            }}
                          >
                            <button
                              onClick={() =>
                                toggleModal(
                                  item.boucherList.map(
                                    (boucher) => boucher.boucherImage
                                  )
                                )
                              }
                            >
                              {item.boucherList.length !== 0 && (
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="eye-icon"
                                />
                              )}
                            </button>
                          </td>
                        )}
                        <td>
                          {new Date(
                            item.startDateInvestment.split("-")[0],
                            item.startDateInvestment.split("-")[1] - 1,
                            item.startDateInvestment.split("-")[2]
                          ).toLocaleDateString("es-PE", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div
                  className={`flex flex-col justify-center text-xl text-center ${
                    isDarkModeState ? "text-white" : "text-black"
                  }`}
                >
                  <p>No encontramos información....</p>
                </div>
              )}

              <ModalComponent
                isOpen={modalOpenState}
                onClose={toggleModal}
                voucherImages={voucherImages}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
