import SideBarComponent from "../components/SideBarComponent";
import UserMenu from "../components/UserMenu";
import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import CalendarComponent from "../components/CalendarComponent";
import { REMAINING_PAYMENT_DAYS, PAST_PAYMENT_DAYS } from "../graphql/querys";
import { useNavigate } from "react-router-dom";

import client from "../client";
import CardRentability from "../components/CardRentability";
import ImportantDayListComponent from "../components/ImportantDayListComponent";

const CalendarPage = ({ handleDaySelect, selectedDateState }) => {
  const [isDarkModeState, setIsDarkModeState] = useState(false);
  const [currentMonthState, setCurrentMonthState] = useState(
    new Date().getMonth()
  );
  const [currentYearState, setCurrentYearState] = useState(
    new Date().getFullYear()
  );
  const [showPassRentabilityState, setPassRentabilityState] = useState(false);

  const {
    loading: loadingRemaining,
    error: errorRemaining,
    data: dataRemaining,
  } = useQuery(REMAINING_PAYMENT_DAYS, { client });

  const { loading: loadingPast, error: errorPast, data: dataPast } = useQuery(
    PAST_PAYMENT_DAYS,
    {
      client,
      fetchPolicy: "network-only",
    }
  );
  // eslint-disable-next-line no-unused-vars
  const handleButtonClick = (buttonStateRentability) => {
    setPassRentabilityState(buttonStateRentability);
  };

  const getButtonStylesRentability = (isSelectedButton, isDarkModeState) => {
    const fontWeight = isSelectedButton ? "font-bold" : "";
    const textColor = isSelectedButton
      ? isDarkModeState
        ? "text-custom-lightblue"
        : "text-custom-blue"
      : isDarkModeState
      ? "text-gray-200"
      : "text-gray-700";

    return { fontWeight, textColor };
  };
  let navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }

    const getDarkModeFromLocalStorage = () => {
      const darkModeFromStorage = localStorage.getItem("isDark");
      if (darkModeFromStorage !== null) {
        setIsDarkModeState(darkModeFromStorage === "true");
      }
    };

    getDarkModeFromLocalStorage();
  }, [navigate]);

  if (loadingRemaining || loadingPast) return <p></p>;

  if (errorRemaining || errorPast) return <p>Error: hubo un problema</p>;

  // merge data pastPaymentDays and remainingPaymentDays in one object

  const importantDays = {
    remainingPaymentDays: dataRemaining.remainingPaymentDays ?? [],
    pastPaymentDays: dataPast.pastPaymentDays ?? [],
  };

  if (
    !importantDays ||
    (importantDays.remainingPaymentDays.lenght === 0 &&
      importantDays.pastPaymentDays.lenght === 0)
  ) {
    return <p>No hay datos disponibles.</p>;
  }

  return (
    <div
      className={`${
        isDarkModeState ? "bg-background-dark " : "bg-custom-background-light"
      } text-${
        isDarkModeState ? "gray-200" : "gray-700"
      } h-full w-full min-h-screen`}
    >
      <div className="flex flex-row h-full gap-15">
        <SideBarComponent
          isDarkModeState={isDarkModeState}
          currentPage="calendar"
        />
        <div className="flex flex-col min-h-screen p-10 ml-auto mr-auto max-w-1800 m-15 ">
          <UserMenu
            isDarkModeState={isDarkModeState}
            setIsDarkModeState={setIsDarkModeState}
          />
          <div className="flex items-center justify-between mb-10 sm:p-5">
            <p
              className={`text-2xl font-bold ${
                isDarkModeState ? "text-custom-lightblue" : "text-custom-blue"
              }`}
            >
              Mi calendario
            </p>
          </div>
          <div className="flex flex-row sm:flex-col lg:flex-col xl:flex-row justify-evenly">
            <div className="flex items-center justify-center">
              <CalendarComponent
                onSelectDay={handleDaySelect}
                selectedDateState={selectedDateState}
                // importantDates={importantDays.map((day) => day.date)}
                setCurrentMonthState={setCurrentMonthState}
                setCurrentYearState={setCurrentYearState}
                isDarkModeState={isDarkModeState}
                importantDays={importantDays}
              />
            </div>
            <div className="flex flex-col md:ml-0 xl:ml-20 xl:mt-2 sm:mt-10 md:mt-10 md:items-center md:content-center">
              <div
                className={`flex rentability-text  md:justify-center md:mr-12          ${
                  isDarkModeState ? "text-custom-lightblue" : "text-custom-blue"
                }`}
              >
                <p className="text-2xl font-bold">Pagos de mi Rentabilidad</p>
              </div>
              <div className="flex justify-around">
                <div className="flex flex-col">
                  <button
                    className={`button-rentability px-5 py-2 m-10 ${
                      getButtonStylesRentability(
                        showPassRentabilityState ? false : true,
                        isDarkModeState
                      ).textColor
                    } ${
                      getButtonStylesRentability(
                        showPassRentabilityState ? false : true,
                        isDarkModeState
                      ).fontWeight
                    }`}
                    onClick={() => {
                      setPassRentabilityState(false);
                    }}
                  >
                    Próximos
                  </button>
                  {showPassRentabilityState ? null : (
                    <div className="w-32 border-4 rounded-xl line-chart border-custom-lightblue mt-[-34px] ml-[29px]"></div>
                  )}
                </div>
                <div className="flex flex-col">
                  <button
                    className={`button-rentability px-5 py-2 m-10 ${
                      getButtonStylesRentability(
                        showPassRentabilityState ? true : false,
                        isDarkModeState
                      ).textColor
                    } ${
                      getButtonStylesRentability(
                        showPassRentabilityState ? true : false,
                        isDarkModeState
                      ).fontWeight
                    }`}
                    onClick={() => setPassRentabilityState(true)}
                  >
                    Pasados
                  </button>
                  {showPassRentabilityState ? (
                    <div className="w-32 border-4 rounded-xl line-chart mt-[-34px] ml-[29px] border-custom-lightblue"></div>
                  ) : null}
                </div>
              </div>

              <div
                className={`relative w-full md:w-[453px]  max-h-[350px] z-0 ${
                  isDarkModeState
                    ? "bg-background-dark"
                    : "bg-custom-background-light"
                }`}
              >
                <div className="relative h-full p-3 overflow-x-hidden overflow-y-auto">
                  <div>
                    <CardRentability
                      showPassRentabilityState={showPassRentabilityState}
                      currentMonthState={currentMonthState}
                      currentYearState={currentYearState}
                      importantDays={importantDays}
                    />
                    {/* <ChartRentability
                      showPassRentabilityState={showPassRentabilityState}
                      currentMonthState={currentMonthState}
                      currentYearState={currentYearState}
                      importantDays={importantDays}
                    /> */}
                  </div>
                </div>
              </div>

              <div>
                <p
                  className={`${
                    isDarkModeState
                      ? "text-custom-lightblue"
                      : "text-custom-blue"
                  } text-2xl font-bold mt-6 ml-9 p-2`}
                >
                  Fechas importantes
                </p>
                <div
                  className={`relative w-full md:w-[453px]  max-h-[350px] z-0 ${
                    isDarkModeState
                      ? "bg-background-dark"
                      : "bg-custom-background-light"
                  }`}
                >
                  <ImportantDayListComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;
