export const saveIsSoles = (isSoles) => {
  localStorage.setItem("isSoles", isSoles);
};

export const getIsSoles = () => {
  return localStorage.getItem("isSoles") ?? "true";
};

export const removeIsSoles = () => {
  localStorage.removeItem("isSoles");
};
